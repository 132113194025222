<template>
  <h1>Oops! Page Not Found</h1>
</template>

<script>
export default {
name:"NotFound"
}
</script>

<style>

</style>